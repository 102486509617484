import {
  FC,
  ReactNode,
  createContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import axios from 'src/utils/axios';
import PropTypes from 'prop-types';

import { User, RoleCode } from '../utils/types';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  token: string | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, name: string, password: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    token: string | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    token: string;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  token: null,
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user, token } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      token,
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user, token } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      token,
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null,
    token: null,
  }),
  REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const initialized = useRef(false);

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    const initialize = async (): Promise<void> => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const tokenFromParams = urlParams.get('token');

        if (tokenFromParams) {
          localStorage.setItem('accessToken', tokenFromParams);

          // Limpiar la URL sin el token
          const newUrl = `${window.location.origin}${window.location.pathname}`;
          window.history.replaceState({}, document.title, newUrl);
        }

        // Obtener el token desde localStorage
        const tokenFromStorage = localStorage.getItem('accessToken');

        if (!tokenFromStorage) {
          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated: false, user: null, token: null },
          });

          setTimeout(() => {
            window.open('https://epic.101grados.tech/auth/signin', '_self');
          }, 500);
          return;
        }

        // Intentar obtener información del usuario
        // Intentar obtener información del usuario
        try {
          const { data: user } = await axios.get<User>('/auth/users/my-info', {
            headers: {
              Authorization: tokenFromStorage
                ? `Bearer ${tokenFromStorage}`
                : undefined,
            },
          }); // Asegúrate de que este es el endpoint correcto

          if (!user)
            throw new Error('No se pudo obtener la información del usuario');

          setSession(tokenFromStorage);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
              token: tokenFromStorage,
            },
          });
        } catch (error: any) {
          console.error('[INIT] Error al obtener usuario:', error);

          // Si el error es 401 (token inválido o expirado), limpiar sesión y redirigir
          if (error[0].status === 401) {
            console.warn('[INIT] Token inválido. Redirigiendo en 2s...');
            setSession(null); // Borra el token
            dispatch({
              type: 'INITIALIZE',
              payload: { isAuthenticated: false, user: null, token: null },
            });

            setTimeout(() => {
              window.open('https://epic.101grados.tech/auth/signin', '_self');
            }, 500);
            return;
          }

          // NO BORRAR EL TOKEN INMEDIATAMENTE SI NO ES 401
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              token: tokenFromStorage,
            },
          });
        }
      } catch (error) {
        console.error('[INIT] Error general en la inicialización:', error);
        dispatch({
          type: 'INITIALIZE',
          payload: { isAuthenticated: false, user: null, token: null },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    //   const { data } = await axios.post<{
    //     accessToken: string;
    //     refreshToken: string;
    //     roles: RoleCode[];
    //     username: string;
    //   }>('/auth/login', {
    //     email,
    //     password,
    //     isGoogleLogin: true,
    //   });
    //   const { accessToken } = data;
    //   setSession(accessToken);
    //   const { data: user } = await axios.get<User>('/system-users/my-info');
    //   dispatch({
    //     type: 'LOGIN',
    //     payload: {
    //       user,
    //       token: accessToken,
    //     },
    //   });
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const response = await axios.post<{ accessToken: string; user: User }>(
      '/api/account/register',
      {
        email,
        name,
        password,
      }
    );
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
