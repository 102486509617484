import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { ConditionalRender } from 'src/components/Guards';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Components
const AdminDashboard = Loader(lazy(() => import('src/content/Home/Dashboard')));

const homeRoutes = [
  {
    path: '',
    element: <Navigate to='users' replace />,
  },
  {
    path: 'admin-dashboard',
    element: (
      <ConditionalRender roleCodes={['SA', 'AD', 'FIN']}>
        <AdminDashboard />
      </ConditionalRender>
    ),
  },
];

export default homeRoutes;
