import { FC, ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from 'src/hooks';
import Login from 'src/content/pages/Auth/Login/Cover';

interface AuthenticatedProps {
  children: ReactNode;
}

const Authenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Login />;
  }

  if (location.pathname === '/') {
    return <Navigate to='/home/admin-dashboard' replace />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  // TODO: redirect based on user role
  // Redirect based on user role
  // if (location.pathname === '/') {
  //   switch (auth.user?.roles[0].code) {
  //     case 'SA':
  //       return <Navigate to="/home/admin-dashboard" replace />;
  //     case 'AD':
  //       return <Navigate to="/home/admin-dashboard" replace />;
  //     case 'FIN':
  //       return <Navigate to="/management/bills/list" replace />;
  //     default:
  //       return <Navigate to="/home/admin-dashboard" replace />;
  //   }
  // }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node,
};

export default Authenticated;
